@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

:root {
  --aspect-ratio: 0.5625;
}

.posIcon {
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.05vh;
  padding-top: 4px;
  font-family: Inter;
  line-height: 1em;
}

.payment {
  font-size: 0.95em;
  font-weight: 500;
  letter-spacing: 0.05vh;
  font-family: Inter;
  line-height: 1.2em;
}

.ant-drawer-body {
  overflow: hidden;
}

.productName1 {
  font-size: 0.9vw !important;
  font-weight: 500;
  letter-spacing: 0.05vh;
  font-family: Inter;
}

.tableRow {
  font-weight: 400;
  font-family: Inter;
  font-size: 1em;
}

.posMenuIcons {
  font-size: 0.8em;
  letter-spacing: 0.06vh;
  font-weight: 500;
  padding-top: 7px;
  font-family: Inter;
}

.posMenuTill {
  font-size: 0.8em;
  letter-spacing: 0.06vh;
  font-weight: 500;
  padding-top: 7px;
  font-family: Inter;
  width: 5vw;
}

.posCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
}

.productDescription {
  font-size: 0.86vw;
  color: #929098;
  margin-top: -0.5vw;
  font-weight: 500;
  letter-spacing: 0.03vw;
  line-height: 0.9;
  font-family: Inter;
}

.productDescription-sm {
  font-size: 0.86vw;
  color: #929098;
  font-weight: 500;
  letter-spacing: 0.03vw;
  line-height: 1.1;
  font-family: Inter;
  padding: 1% 0;
}

.productDescription-kiosk {
  font-size: 1em;
  color: #0f0718;
  font-weight: 500;
  letter-spacing: 0.03vw;
  line-height: 1.1;
  font-family: Inter;
  padding: 3% 0;
}

.ant-checkbox-group-item {
  padding-bottom: 8px;
}
.ant-card-body {
  padding: 0;
}
.ant-col-1 {
  flex: 0 0 0%;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px;
}
.tillEventsScroll {
  height: 28vh !important;
  width: "0.1vw";
}
.incomingDataScroll {
  height: 70vh !important;
}
.orderListScroll {
  height: 35vh !important;
}
.ordersDataScroll {
  height: 31vh !important;
}

.cartStyle {
  font-weight: 600;
  align-items: end;
  display: flex;
  font-size: 1.57vw;
  padding-left: 1.6%;
  color: #0f0718;
}

.cartSelect {
  font-size: 0.8em;
  /* color: #1e1e1e !important; */
  color: #929098 !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: left;
}

.cartOption {
  font-size: 0.9em;
  color: #0f0718;
  font-weight: 500;
  text-align: left;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(146, 144, 152, 0.5) !important;
  color: #1e1e1e;
  font-weight: 500;
}

/* style={{justifyContent:"center",alignItems:"center",display:"flex"}} */

.ant-table-thead > tr > th {
  background: #f3f4f9 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.ant-table {
  background: var(--table-bg-color) !important; /*#f3f4f9*/
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #f3f4f9 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-table-thead > tr > th {
  font-size: 1.2vw !important;
  color: #000 !important;
}

.ant-table-tbody > tr:hover {
  background: #f3f4f9 !important;
}

.table-stl > .ant-table-tbody > tr > td {
  /* border: none; */
  font-size: 1.6vw !important;
}

/* .moreOptions>.ant-select-arrow {
  top: 65%;
} */
.ant-select-selector {
  border: 1px solid #fff !important;
  height: 5vh !important;
}

.supplier .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  height: 7vh !important;
  font-weight: 400 !important;
  font-size: 1vw !important;
}

.ant-select {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  color: #1e1e1e;
}

.moreOptions > .ant-select-dropdown {
  border-radius: 5px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f3f4f9 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2f3856 !important;
  border-color: #2f3856 !important;
  border-radius: 4px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2f3856 !important;
  border-radius: 4px !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #a4cd38 !important;
}

.ant-checkbox-inner {
  border-radius: "3px" !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  top: "6px" !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white;
  /* Change the border color to white */
}

.inputAdd input::placeholder {
  font-size: 8px;
  /* Set the desired font size for the placeholder */
}

/* CART page styles */

.cart-table .ant-table {
  font-weight: 500;
  font-size: 1.1vh;
}

.cart-table .ant-table-thead > tr > th {
  font-size: 0.9vw !important;
  color: #0f0718 !important;
  font-weight: 600;
  background-color: #fff !important;
  padding-top: 1.5vh !important;
  border-bottom: none !important;
  padding-bottom: 0.8vh !important;
}

.cart-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.cart-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: center !important;
}

.cart-table .ant-table-tbody > tr > td {
  padding: 1.5vh;
  color: #0f0718 !important;
}

.taxDisLbl {
  font-weight: 600 !important;
}

.selecItem .ant-select-single .ant-select-selection-item {
  font-size: 0.9vw !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: 500 !important;
  color: #1e1e1e;
}

.selecItem.ant-select-selector:hover {
  border: none !important;
}

.selecItem .ant-select-selector {
  height: 8vh !important;
}

.selecItem.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #92909880 !important;
  font-weight: 400;
  font-size: 1em;
  display: flex;
  align-items: center;
}
.selecItem .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-top: 10px;
}

.selecItem {
  width: 10vw;
  height: 8vh;
}

.selectType .ant-select-single .ant-select-selection-item {
  font-size: 0.9vw !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: 500 !important;
  color: #1e1e1e;
}

.selectType.ant-select-selector:hover {
  border: none !important;
}

.selectType .ant-select-selector {
  height: 5vh !important;
  width: 17.5vw !important;
}

.selectType.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #92909880 !important;
  font-weight: 400;
  font-size: 1em;
  display: flex;
  align-items: center;
}
.selectType .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-top: 10px;
}

/* .selectType {
  width: 10vw;
  height: 6vh;
} */

.customerInput .ant-input {
  font-size: 11px !important;
  margin-left: -0.7vw !important;
  color: #000 !important;
  font-weight: 600 !important;
  height: 3.6vh;
}

.customerInput .ant-input::placeholder {
  color: #929098 !important;
  font-size: 1.05vw;
}

.customerInput .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
}

.productSearch.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none !important;
  border-color: #fff !important;
}

.productSearch-po.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #d9d9d9 !important;
}

.ant-input-affix-wrapper:hover {
  border: 1px solid #fff !important;
}

.listItems {
  list-style: none;
  font-size: 1.5vh;
  padding-top: 2vh;
  font-weight: 500;
  color: #0f0718;
  /* listStyle: 'none', fontSize:"1.2vh",paddingTop:"2vh" */
}

.productSearch .ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

.productSearch.ant-input-affix-wrapper {
  border: none !important;
}

.productSearch .ant-input-prefix {
  margin-right: 0.8vw;
}

.customer-search .ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw !important;
  margin-left: -1.5vw;
  font-weight: 550;
  font-family: Inter;
}

.customer-search.ant-input-affix-wrapper {
  border: 1px solid #d9d9d9 !important;
}

.customer-search .ant-input-prefix {
  margin-right: 0.8vw;
}

.customer-search .ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.16vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

/* letter-spacing: 0.08vh !important; */
/* opacity: 0.7; */
/* .productSearch-sm .ant-input::placeholder {
  color: #908f96 !important;
  font-size: 1.1vw;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter; 
} */

.productSearch-sm .ant-input-prefix {
  margin-right: 0.8vw;
}

.productSearch-sm {
  font-size: 1vw;
}

.productSearch-sm .ant-input,
.productSearch-sm .ant-input::placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.productSearch-sm:hover {
  border: 1px solid #ccc !important;
}

.bomSearch-sm .ant-input-prefix {
  margin-right: 0.8vw;
}

.bomSearch-sm {
  font-size: 1vw;
}

.bomSearch-sm .ant-input,
.bomSearch-sm .ant-input::placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.bomSearch-sm:hover {
  border: 1px solid #ccc !important;
}

.custom-select .ant-select-selector {
  height: 7.7vh !important;
  /* Set the desired height */
  border: 1px solid #fff !important;
  color: #1e1e1e;
  font-weight: 400;
  font-size: 1em !important;
}

.custom-select.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2% !important;
}

/* Dicount Modal */

.discount-remove-btn {
  width: 100%;
  font-size: 1.2em;
  border: 1px solid rgba(146, 144, 152, 0.5);
  color: #2f3856;
  border-radius: 4px;
  font-weight: 500;
  padding: 3px;
  height: 7vh;
}

.discount-remove-btn:hover {
  color: #2f3856 !important;
  background-color: #fff !important;
  border-color: #2f3856 !important;
}

.discount-apply-btn {
  width: 100%;
  font-size: 1.2em;
  background-color: #2f3856;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  /* letter-spacing: 0.2vh; */
  padding: 4px;
  height: 7vh;
}

.discount-apply-btn:hover {
  background-color: #2f3856 !important;
  color: #fff !important;
  border-color: #2f3856 !important;
}

.discount-value-input .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
}

.discount-value-input.ant-input::placeholder {
  color: #bfbfbf !important;
  font-family: Inter;
  font-size: 1em !important;
  font-weight: 400;
}

.discount-value-input {
  border: 1px solid #fff;
  height: 6vh;
  font-size: 1vw;
}

/* Parked Bills */

.parkedTable .ant-table-thead > tr > th {
  font-size: 1.37vw !important;
}

.parkedTable .ant-table-thead > tr > th {
  border-bottom: none !important;
  font-weight: 600;
  color: #0f0718;
}

.parkedTable .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #0f0718;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.parkedTable .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.ant-table-expanded-row > td {
  padding: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-left: 0;
  padding-right: 0;
}

.parkedTable .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.parkedTable .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.parkedTable .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.td-bottons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* font-size: 0.9vw; */
  font-size: 1vw;
  font-weight: 500;
}

.parkedTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-top: 1.6vw;
  padding-bottom: 1.6vw;
  font-size: 1.2vw;
}

.td-nested-title {
  font-size: 1.23vw !important;
  font-weight: 600;
}

.td-subtotal {
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(52, 40, 44, 0.55);
  padding-bottom: 2px;
  font-weight: 400;
}

.td-subTotalText {
  font-size: 1em;
  color: #0f0718;
  font-weight: 400;
}

.td-total {
  font-weight: 500;
  font-size: 1em;
}

/* .td-nested-description {
  font-size: 1.2em !important;
  font-weight: 400;
} */

.parkedCustomer {
  height: 3.8vw;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.parkedCustomer .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
  font-size: 12px !important;
}

.parkedCustomer .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.parkedCustomer .ant-input::placeholder {
  color: #929098 !important;
}

/* Sales History  */

.salesHistory-input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 6.6vh;
}

.salesHistory-input .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-input .ant-input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
}

.salesHistory-doc-input.ant-input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.salesHistory-doc-input .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-doc-input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 6.6vh;
}

.salesHistory-datePicker {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 6.6vh;
}

.salesHistory-datePicker .ant-picker-input {
  flex-direction: row-reverse;
}

.salesHistory-datePicker .ant-picker-input input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.ant-picker-suffix {
  margin-right: 0.9vw;
}

.ant-picker-suffix {
  /* Adjust icon positioning */
  right: auto !important;
  left: 8px;
}

.salesHistory-table .ant-table-thead > tr > th {
  font-size: 1.4vw !important;
  color: #0f0718;
  border-bottom: none !important;
  font-weight: 600;
}

.salesHistory-table .ant-table-tbody > tr:first-child > td {
  border-bottom: 0px solid rgba(0, 0, 0, 0.27) !important;
}

.salesHistory-table .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #0f0718;
}

.salesHistory-table .ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.27) !important;
}

.salesHistory-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
}

.salesHistory-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
}

.salesHistory-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.salesHistory-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.salesHistory-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.salesHistory-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.custom-border {
  position: relative;
  height: 1px;
}

.custom-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 2px dashed;
  margin-top: 8px;
}

/* Payment scree */
.itemScroll {
  min-height: 15vh;
  max-height: 30vh;
  height: auto;
  padding-left: 1.55%;
  margin-top: 0.4vw;
}

.payment-details {
  color: #0f0718;
  font-weight: 600;
  font-size: 1.05vw;
  margin-bottom: 4px;
}

/* Cash Management */

.cash-management .ant-table-thead > tr > th {
  font-size: 1.37vw !important;
  font-weight: 600;
  color: #0f0718;
}

.cash-management .ant-table-tbody > tr > td {
  font-weight: 550;
  color: #0f0718;
}

.cash-management .ant-table-tbody > tr > td:nth-child(4) {
  text-align: right !important;
  font-size: 1.37vw !important;
}

.cash-management .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  padding-left: 1.2vw !important;
}

.cash-management .ant-table-thead > tr > th:nth-child(4) {
  padding-right: 0px !important;
}

.cash-management .ant-table-thead > tr > th:nth-child(1) {
  padding-left: 0px !important;
}

/* Sales Return */

.salesReturn-table {
  height: 51vh;
}

.salesReturn-table .ant-table-thead > tr > th {
  font-size: 1.4vw !important;
  color: #0f0718;
  border-bottom: none !important;
  font-weight: 600;
}

.salesReturn-table .ant-table-tbody > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.27) !important;
}

.salesReturn-table .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #0f0718;
}

.salesReturn-table .ant-table-tbody > tr {
  font-weight: 500;
  color: #0f0718;
}

.salesReturn-table .ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.27) !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(1) {
  padding-left: 1vw;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(2) {
  text-align: center !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: center !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
  padding-right: 0px !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.sm-stock-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
  padding: 0px 1em;
  background: #fff !important;
}

.sm-stock-table .ant-table-tbody > tr > td {
  font-size: 0.8em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  /* border-bottom: 1px solid #00000045 !important; */
  padding: 0.7vw 0.4vw !important;
  text-align: left !important;
}

.sm-stock-table .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 0.2vw 0.4vw !important;
  /* border-bottom: 1px solid #00000045 !important; */
  text-align: left !important;
}

/* Super Market */

.sm-stock-table-BOM .ant-table {
  font-weight: 500;
  font-size: 1vw;
  padding: 0px 1em;
  background: #fff !important;
}

.sm-stock-table-BOM .ant-table-tbody > tr > td {
  font-size: 0.8em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  text-align: left !important;
}

.sm-stock-table-BOM .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 0.2vw 0.4vw !important;
  text-align: left !important;
}

.sm-cart-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}

.sm-cart-table .ant-table-thead > tr > td {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-cart-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  font-size: 1.2em;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(6) {
  text-align: left !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(6) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(7) {
  text-align: left !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(7) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-cart-table .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-cart-table .ant-table-tbody > tr {
  border-bottom: none !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table .ant-table-thead {
  padding-left: 1% !important;
}

/* dashboard table */
.sm-dashboard-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
  background: #fff !important;
}

.sm-dashboard-table .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}

.sm-dashboard-table .ant-table-thead > tr > td {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  font-size: 1.2em;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(6) {
  text-align: left !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(6) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-dashboard-table .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-dashboard-table .ant-table-tbody > tr {
  border-bottom: none !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

/* .sm-dashboard-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
} */

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: left !important;
}

/* .sm-dashboard-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
  padding-left: 8vw !important;
} */

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: left !important;
  padding-left: 1.2vw !important;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: left !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(1) {
  text-align: left !important;
  padding-left: 16px;
}

/* .sm-dashboard-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
} */

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-dashboard-table .ant-table-thead {
  padding-left: 1% !important;
}

/* dashboard table */

/* view order table */
.sm-view-order-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-view-order-table .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}

.sm-view-order-table .ant-table-thead > tr > td {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-view-order-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  font-size: 1.2em;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(6) {
  text-align: left !important;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(6) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-view-order-table .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-view-order-table .ant-table-tbody > tr {
  border-bottom: none !important;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(5) {
  /* padding-left: 0; */
  text-align: center !important;
  padding-right: 35px !important;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  padding-left: 16px !important;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: left !important;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
  /* padding-left: 1.5vw !important; */
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
  /* padding-left: 1.5vw !important; */
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-view-order-table .ant-table-thead {
  padding-left: 1% !important;
}

/* cash management table */

.sm-cashmanagement-table .ant-table {
  font-weight: 500;
  /* font-size: 1.1vh; */
  font-size: 1vw;
}
.sm-cashmanagement-table .ant-table-thead > tr > th {
  /* font-size: 1.33vw !important; */
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}
.sm-cashmanagement-table .ant-table-thead > tr > td {
  font-size: 1.03vw !important;
  color: #0f0718 !important;
  font-weight: 500 !important;
}
.sm-cashmanagement-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  background-color: #fff !important;
  padding-left: 1vw;
  font-size: 1.2em !important;
  font-weight: 400 !important;
  color: #0f0718 !important;
}

.sm-cashmanagement-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
  padding-right: 2vw;
}

.addtInfo {
  font-size: 1.2em;
  font-weight: 400;
  color: #0f0718;
  font-family: Inter;
}

.ant-progress-text {
  color: #0f0718 !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}

/* Super Market Return */
.sm-cart-table-return .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table-return .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #fff !important;
}

.sm-cart-table-return .ant-table-thead > tr > td {
  /* font-size: 1.03vw !important; */
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td {
  border-bottom: 1px solid #fff !important;
  background: #fff;
  font-size: 1.2em !important;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

/* .sm-cart-table-return .ant-table-thead>tr>td:nth-child(6) {
  text-align: center !important;
} */

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(6) {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding-left: 5vw;
}

.sm-cart-table-return .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 1vw;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(1) {
  padding: 1.05vw !important;
}

/* //////////////////// */

.sm-payment-search .ant-input {
  text-align: right !important;
  font-weight: 600 !important;
}

.ant-table-expanded-row > td {
  padding: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-left: 0;
  padding-right: 0;
}

.td-nested-description-sm {
  /* font-size: 1.1vw !important; */
  font-weight: 400;
  /* border-bottom: 1px solid rgba(52, 40, 44, 0.55); */
}

/* .td-nested-title-sm {
  font-size: 1.1vw !important;
  font-weight: 600;
} */

/* For pos table */

.sm-cart-table-pos .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table-pos .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: var(--table-bg-color) !important;
  padding: 1.2vh 0.9vw !important;
  border-bottom: none !important;
  font-family: Inter !important;
}

.sm-cart-table-pos .ant-table-thead > tr > td {
  /* font-size: 1.03vw !important; */
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td {
  padding: 1vh;
  font-size: 1vw;
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos .ant-table-tbody > tr {
  border-bottom: 1px solid #908f96 !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(2) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(2) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

/* .parkedCustomer-sm {
  height: 2.8vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.parkedCustomer-sm .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
  font-size: 12px !important;
}

.parkedCustomer-sm .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.parkedCustomer-sm .ant-input::placeholder {
  color: #929098 !important;
  opacity: 0.8;
} */

.sm-cart-table-pos-stock .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: var(--table-bg-color) !important;
  padding: 1.2vh 0.9vw !important;
  border-bottom: none !important;
  font-family: Inter !important;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > td {
  /* font-size: 1.03vw !important; */
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td {
  padding: 1vh;
  font-size: 1vw;
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr {
  border-bottom: 1px solid #908f96 !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(3) {
  text-align: right !important;
  padding-right: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(3) {
  text-align: right !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(4) {
  text-align: right !important;
  padding-right: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(4) {
  text-align: right !important;
}

.parkedCustomer-sm {
  height: 2.8vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-size: 1vw;
}

.parkedCustomer-sm .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
  font-size: 12px !important;
}

.parkedCustomer-sm .ant-input {
  /* font-size: 1.2vw !important; */
  font-size: 1em;
  font-weight: 400 !important;
  color: #929098 !important;
  /* color: #000 !important; */
  margin-left: -0.8vw !important;
}

.parkedCustomer-sm .ant-input::placeholder {
  color: #929098 !important;
  opacity: 0.8;
}

/* .salesHistory-input-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  height: 5vh;
}

.salesHistory-input-sm .ant-input {
  font-size: 1vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-input-sm .ant-input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  opacity: 0.8;
} */

.salesHistory-input-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  font-size: 1vw;
  /* height: 5vh; */
}
/* .sm-product-search{
  font-size: 0.95rem;
} */

.salesHistory-input-sm .ant-input {
  font-size: 1em !important;
  font-weight: 400 !important;
  /* color: #000 !important; */
  margin-left: -0.5vw !important;
}

.salesHistory-input-sm .ant-input::placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  opacity: 0.8;
}

.salesHistory-doc-input-sm.ant-input::placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  font-size: 1vw !important;
  opacity: 0.8;
}

.salesHistory-doc-input-sm .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-doc-input-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  height: 5vh;
}

.salesHistory-datePicker-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  height: 5vh;
}

.salesHistory-datePicker-sm .ant-picker-input {
  flex-direction: row-reverse;
}

.salesHistory-datePicker-sm .ant-picker-input input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1vw;
  opacity: 0.8;
}

/* Kiosk screen Table */

.kiosk-cart-table-pos .ant-table {
  font-weight: 500;
  font-size: 1.1vh;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th {
  font-size: 1.03vw !important;
  color: #0f0718 !important;
  font-weight: 600;
  background-color: #fff !important;
  padding: 1.2vh 0.9vw !important;
  border-bottom: 1px solid rgba(146, 144, 152, 0.5) !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > td {
  font-size: 1.03vw !important;
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(146, 144, 152, 0.5) !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > td:nth-child(6) {
  text-align: left !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td {
  padding: 1.5vh;
  color: #0f0718 !important;
  font-weight: 500 !important;
  height: 8.6vh;
}

.kiosk-cart-table-pos .ant-table-tbody > tr {
  border-bottom: none !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.KioskDescription {
  list-style: none;
  font-size: 1vw;
  padding-top: 2vh;
  font-weight: 500;
  color: #0f0718;
  line-height: 1.2;
  /* listStyle: 'none', fontSize:"1.2vh",paddingTop:"2vh" */
}

.offers-text {
  padding: 1.16vh;
  margin-bottom: 0;
  font-size: 0.75vw;
  font-family: Inter, sans-serif;
  color: #fff;
  font-weight: normal;
  width: 80%;
  overflow: hidden;
  background-color: #626367;
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
}

.custom-menu-item .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 10px !important;
}

.ant-drawer-mask {
  background: none;
}

.ant-drawer-content {
  background: #f3f4f9;
}

.filter > .ant-drawer-mask {
  background: #000000a6;
}

.ant-drawer-content {
  border-radius: 10px !important;
}

.ant-drawer-header {
  background: #f3f4f9;
  border-bottom: 1px solid #f3f4f9;
}

.addCustomer > .ant-drawer-mask {
  background: #000000a6;
}

.ant-drawer-content {
  border-radius: 10px !important;
}

/* Keyboard styles */

.qrlogin-btn {
  width: 100%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

.hg-theme-default {
  background-color: #fff !important;
}

.email.hg-theme-default {
  background-color: transparent;
}

.email.simple-keyboard.hg-layout-default .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  border-radius: 0.9rem;
  height: 9vh;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
}

.email.simple-keyboard.hg-theme-default .hg-row:nth-child(2) {
  margin-left: 2vw;
  margin-right: 2vw;
}

.simple-keyboard.hg-layout-default .hg-button.hg-yellow {
  /* background: #2f3856 !important; */
  color: #000 !important;
  width: 13vw;
}

.simple-keyboard.hg-layout-default .hg-button.hg-mail {
  width: 13vw;
}

.email.simple-keyboard.hg-theme-default .hg-row:nth-child(4) {
  margin-left: 3vw;
  margin-right: 3vw;
}

.hg-theme-default .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 3px 6px #00000026 !important;
  border-bottom: 1px solid #fff !important; */
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  border-radius: 0.9rem;
  height: 7.6vh;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
}

.hg-theme-default .hg-row:nth-child(2) {
  padding: 0;
}

.qtyReturn.ant-input:focus {
  box-shadow: 0 0 0 2px #fff !important;
  border-color: #92909880 !important;
}

.ant-switch-checked {
  background-color: #2f3856 !important;
}

.custom-dropdown {
  /* Add your styles for the dropdown options here */
  /* For example: */
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
}
/* Notes Popup Styles */

.aprvl.hg-theme-default {
  background-color: transparent !important;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: Inter;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  font-size: 1vw;
}
.aprvl.hg-theme-default .hg-row .hg-button {
  align-items: center;
  background: rgba(146, 144, 152, 0.5);
  color: #0f0718;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  font-family: Inter;
  font-weight: 500;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
}

.tableSelected {
  background-color: #f5f5f5;
}

.tableNonSelected {
  background-color: #fff;
}

.custom-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  display: flex;
  justify-content: flex-end;
}

.custom-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}
